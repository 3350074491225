import Club from "shared/containers/Benefits/Club";
import { BrandIndex } from "shared/pages/BrandIndex";
import { ServerSideFunction } from "shared/tools/ServerSideFunction";

export const getServerSideProps = (context) => {
  return ServerSideFunction(context);
};

export default function Index({ environmentType, isClub }) {
  // Aqui não é feita a verificação do environmentType, pois sempre será holding nesse ponto e
  // pela rota /[brandAlias]/ devemos exibir a página club do cliente

  return isClub ? <Club isClub={isClub} /> : <BrandIndex />;
}
